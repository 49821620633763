import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useASyncUsersState, useRequestState } from 'store/selectors';
import ASyncUser from '../../../models/Admin/ASyncUser';
import { useAppDispatch } from '../../../store';
import CreateSyncUserModal from './CreateSyncUserModal';
import SyncUserModalEditCompanies from './SyncUserModalEditCompanies';
import SyncUserRow from './SyncUserRow';

const SyncUsersTab = () => {
	const dispatch = useAppDispatch();

	const users = useASyncUsersState();

	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [openModalEditCompanies, setOpenModalEditCompanies] = React.useState<boolean>(false);

	const [user, setUser] = React.useState<ASyncUser | null>(null);

	const closeDialog = React.useCallback(() => {
		setOpenModal(false);
	}, []);
	const openDialog = React.useCallback(() => {
		setOpenModal(true);
	}, []);

	const closeDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(false);
	}, []);
	const openDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(true);
	}, []);

	return (
		<>
			<CreateSyncUserModal user={user} onClose={closeDialog} open={openModal} />
			<SyncUserModalEditCompanies user={user} onClose={closeDialogEditCompanies} open={openModalEditCompanies} />

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="Users table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: '#0859a4' }} component="h6">
									SERVER
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									USERNAME
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									COMPANIES
								</TableCell>
								<TableCell
									sx={{ color: '#0859a4', paddingLeft: 3, paddingRight: 3 }}
									component="h6"
									align="right"
								>
									ACTION
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.loading ? (
								<div
									style={{
										width: '100vw',
										height: 200,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress size={50} />
								</div>
							) : (
								users.list.map((user, index) => (
									<SyncUserRow
										key={index}
										user={user}
										setUser={setUser}
										openDialog={openDialog}
										openDialogEditCompanies={openDialogEditCompanies}
									/>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default SyncUsersTab;

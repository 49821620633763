import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AServersState, Timing, ErrorTrigger, AUsersState } from 'store/types';
import AServer from '../../models/Admin/AServer';
import { extractErrorMessageFromResponse } from 'lib/utils/redux';
import AUser from 'models/Admin/AUser';

const initialState: AUsersState = {
	list: [],
	error: '',
	loading: false,
	errorTrigger: 0,
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aUsers',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorTrigger: (state, action: PayloadAction<ErrorTrigger>) => {
			state.loading = false;
			state.errorTrigger = Timing.now();
			state.error = extractErrorMessageFromResponse(action.payload);
		},
		setList: (state, action: PayloadAction<AUser[]>) => {
			state.loading = false;
			state.list = action.payload;
		},
		handleCreate: (state, action: PayloadAction<AUser>) => {
			state.loading = false;
			state.list = [...state.list, action.payload];
		},
		handleUpdate: (state, action: PayloadAction<AUser>) => {
			state.loading = false;
			const index = state.list.findIndex(
				(element) => element.id === action.payload.id && element.serverId === action.payload.serverId
			);
			if (index === -1) return;
			state.list[index] = action.payload;
		},
		handleDelete: (
			state,
			action: PayloadAction<{
				id: string;
				serverId: string;
			}>
		) => {
			state.loading = false;
			state.list = state.list.filter(
				(element) => element.id !== action.payload.id && element.serverId === action.payload.serverId
			);
		},
	},
});

export const { setIsLoading, setErrorTrigger, setList, handleCreate, handleUpdate, handleDelete } = slice.actions;

export default slice.reducer;

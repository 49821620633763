import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AServer from '../../../models/Admin/AServer';
import AUser from '../../../models/Admin/AUser';
import { useAppDispatch } from '../../../store';
import RequestManager from '../../../store/request-manager';
import { useAServersState, useRequestState } from '../../../store/selectors';
import theme from '../../../theme';
import { createSyncUser, updateSyncUser } from 'api-services/sync-user';
import Company from 'models/Company';
import { updateUserCompanies } from 'api-services/user';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
	container: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
});

export interface Props {
	user?: AUser | null;
	open: boolean;
	onClose: () => void;
}

const Modal: React.FC<Props> = ({ user, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const aServersState = useAServersState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [serverId, setServerId] = React.useState<string>('');
	const [companies, setCompanies] = React.useState<Company[]>([]);
	const [companyIds, setCompanyIds] = React.useState<string[]>([]);
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');

	React.useEffect(() => {
		if (!open || !user) {
			return;
		}

		setServerId(user.serverId);
		const ids = user.companies.map((company) => company.id);
		console.log('user.companies : ', user.companies);
		setCompanies(user.companies);
		setCompanyIds(ids);
		setUsername(user.username);
	}, [open]);

	function getComapnies() {
		console.log('user?.serverId : ', user?.id);
		console.log('user?.serverId : ', user?.serverId);
		console.log('user?.serverId : ', user?.server.name);
		console.log('user?.serverId : ', user?.server.code);
		console.log('user?.serverId : ', user?.server.id);
		const server = aServersState.list.find((server) => server.id === user?.serverId);
		if (!server) return [];
		return server.companies.filter((company) => !company.blocked);
	}

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					{user !== null && user !== undefined && (
						<Grid item xs={12} sm={12} md={12}>
							<FormGroup>
								{getComapnies().map((element) => (
									<FormControlLabel
										key={element.id}
										control={
											<Checkbox
												value={element.id}
												checked={companyIds
													.map((e) => parseInt(e, 10))
													.includes(parseInt(element.id, 10))}
												onChange={(e) => {
													if (e.target.checked) {
														if (!companyIds.includes(element.id)) {
															setCompanyIds((prev) => [...prev, element.id]);
														}
													} else {
														const findIndex = companyIds.findIndex(
															(element1) =>
																parseInt(element1, 10) === parseInt(element.id, 10)
														);
														if (findIndex !== -1) {
															const newCompanyIds = [...companyIds];
															newCompanyIds.splice(findIndex, 1);

															setCompanyIds(newCompanyIds);
														}
													}
												}}
											/>
										}
										label={element.description}
									/>
								))}
							</FormGroup>
						</Grid>
					)}
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							onClick={async () => {
								if (user) {
									await updateUserCompanies(user.id, {
										companyIds,
										serverId: parseInt(user.serverId),
									});
								}
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							{isLoading ? <CircularProgress size={18} /> : 'Save'}
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							sx={{ height: '40px' }}
							onClick={onClose}
							fullWidth
							variant="outlined"
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default Modal;

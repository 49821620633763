import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AServersState, Timing, ErrorTrigger } from 'store/types';
import AServer from '../../models/Admin/AServer';
import { extractErrorMessageFromResponse } from 'lib/utils/redux';

const initialState: AServersState = {
	list: [],
	error: '',
	loading: false,
	errorTrigger: 0,
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aServers',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorTrigger: (state, action: PayloadAction<ErrorTrigger>) => {
			state.loading = false;
			state.errorTrigger = Timing.now();
			state.error = extractErrorMessageFromResponse(action.payload);
		},
		setList: (state, action: PayloadAction<AServer[]>) => {
			state.loading = false;
			state.list = action.payload;
		},
		handleCreate: (state, action: PayloadAction<AServer>) => {
			state.loading = false;
			state.list = [...state.list, action.payload];
		},
		handleUpdate: (state, action: PayloadAction<AServer>) => {
			state.loading = false;
			const index = state.list.findIndex((element) => element.id === action.payload.id);
			if (index === -1) return;
			state.list[index] = action.payload;
		},
		handleDelete: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.list = state.list.filter((element) => element.id !== action.payload);
		},
	},
});

export const { setIsLoading, setErrorTrigger, setList, handleCreate, handleUpdate, handleDelete } = slice.actions;

export default slice.reducer;

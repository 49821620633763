import { apiCall } from '../index';
import ASubAdmin from '../../models/Admin/ASubAdmin';
import store from '../../store';

import {
	setIsLoading,
	setErrorTrigger,
	setList,
	handleCreate,
	handleUpdate,
	handleDelete,
} from '../../store/slices/aUsers.slice';
import AUser from '../../models/Admin/AUser';

type CreateBody = {
	firstName: string | null;
	lastName: string | null;
	serverId: string;
	username: string;
};

type UpdateBody = {
	firstName: string | null;
	lastName: string | null;
	serverId: string;
	username: string;
};

type UpdateCompaniesBody = {
	companyIds: string[];
	serverId: number;
};

type BlockBody = {
	serverId: number;
};

export async function getUsers() {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AUser[]>('admin/users');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(setList(res.data!));
}

export async function createUser(body: CreateBody) {
	store.dispatch(setIsLoading(true));

	console.log('body : ', body);

	const res = await apiCall<AUser>('admin/users', true, body, 'post');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleCreate(res.data!));
}

export async function updateUser(id: string, body: UpdateBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AUser>(`admin/users/update/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function blockUser(id: string, body: BlockBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AUser>(`admin/users/block/${id}`, true, body, 'post');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function updateUserCompanies(id: string, body: UpdateCompaniesBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AUser>(`admin/users/update-companies/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function deleteUser(
	id: string,
	body: {
		serverId: number;
	}
) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<{
		status: 200;
		message: 'ok';
	}>(`admin/users/${id}`, true, body, 'delete');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(
		handleDelete({
			id: id,
			serverId: body.serverId.toString(),
		})
	);
}

import store from '../store';

type ApiMethod = 'get' | 'post' | 'put' | 'delete';

function getHeaders(authorization: boolean, method: ApiMethod) {
	const headers: HeadersInit = {};

	if (method !== 'get' && method !== 'delete') {
		headers['Content-Type'] = 'application/json';
	}

	if (authorization) {
		const token = store.getState().authentication.accessToken;
		console.log('token: ', token);

		headers['Authorization'] = `Bearer ${token}`;
	}

	console.log('headers : ', headers);

	return headers;
}

function getQueryStringFromBody(body?: Record<string, unknown>) {
	if (!body) return '';
	return Object.entries(body)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
		.join('&');
}

function getBodyAndApiPath(
	api: string,
	body?: Record<string, unknown>,
	method: ApiMethod = 'get'
): {
	apiPath: string;
	bodyStringified: BodyInit | undefined;
} {
	if (method === 'get') {
		return {
			apiPath: `${api}${getQueryStringFromBody(body)}`,
			bodyStringified: undefined,
		};
	}

	return {
		apiPath: api,
		bodyStringified: JSON.stringify(body),
	};
}

export async function apiCall<ResponseType>(
	api: string,
	authorization: boolean = true,
	body?: Record<string, unknown>,
	method: ApiMethod = 'get'
) {
	try {
		const BASE_URL: string = `${process.env.REACT_APP_API_URL!}/api/v1`;

		const { apiPath, bodyStringified } = getBodyAndApiPath(api, body, method);
		console.log('bodyStringified: ', bodyStringified);

		const res = await fetch(`${BASE_URL}/${apiPath}`, {
			method,
			body: bodyStringified,
			headers: getHeaders(authorization, method),
		});
		const result = (await res.json()) as ResponseType;
		console.log(`${api} result : `, result);
		return {
			data: result,
			status: res.status,
			error: '',
		};
	} catch (error) {
		console.log(`error in api call : ${String(error)}`);

		return {
			data: null,
			status: 555,
			error: `error in api call : ${String(error)}`,
		};
	}
}

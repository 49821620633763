import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'lib/utils/enums';
import Company from 'models/Company';
import Order, { OrderPhase } from 'models/Order';
import moment from 'moment';
import { WorkOrderFormat } from 'types';
import AAdmin from '../models/Admin/AAdmin';
import ASubAdmin from '../models/Admin/ASubAdmin';
import AServer from '../models/Admin/AServer';
import ASyncUser from '../models/Admin/ASyncUser';
import AUser from '../models/Admin/AUser';
import Filter from '../models/Filter';
import InventoryItem from '../models/InventoryItem';

export type PA<P = void, T extends string = string, M = never, E = never> = PayloadAction<P, T, M, E>;

/**
 * Custom Payload Action.
 * @remarks
 * For when dispatch function will be used in the reducer,
 * especially in extraReducers
 */
export type CPA<T = any> = PayloadAction<T> & { dispatch: Function };

export interface SliceState<T> {
	updatedAt: number;
	list: T[];
}

export interface OrdersState {
	hasMorePages: boolean;
	isPending: boolean;
	isLoading: boolean;
	list: Order[];
	updatedAt: number;
	refresh: number;
}

export interface AuthenticationState {
	username: string;
	status: LoadingState;
	errorMessage?: string;
	isAuthenticated: boolean;
	isAdministrator: boolean;
	isSubAdmin: boolean;
	accessToken: string;
	expiryAt: number;
}

export interface CounterState {
	value: number;
	status: LoadingState;
	updatedAt: number;
}

export enum DragItemTypes {
	WORK_ORDER = 'workorder',
}

export interface DragState {
	value: WorkOrderFormat;
	set: boolean;
	updatedAt: number;
}
export interface CalDate {
	weekNumber: number;
	month: number;
	day: number;
	type: string;
}
export interface CalendarState {
	current: string;
	isDragging: boolean;
	startDate: string;
	endDate: string;
	highlightedDates: string[];
}

export interface UIState {
	pulseOrderId?: string;
	calenderVisible: boolean;
	leftPanelVisible: boolean;
}

export interface WorkOrderState {
	opened: WorkOrderFormat[];
	scheduled: WorkOrderFormat[];
}

interface SignInFeildErrors {
	errors: { userName?: string[]; password?: string[] };
}

export interface SignInState {
	loading: LoadingState;
	errorMessage?: string | null;
	fieldErrors?: SignInFeildErrors;
}

export interface CompanyState {
	loading: LoadingState;
	errorMessage?: string | null;
	list: Company[];
}

export interface MultiDragState {
	list: string[];
	lastSelectedIndex: number;
}

export interface OrderPhasesState {
	list: OrderPhase[];
	updatedAt: number;
}

export interface AAdminsState {
	list: AAdmin[];
	updatedAt: number;
}

interface CommonState {
	updatedAt: number;
	error: string;
	errorTrigger: number;
	loading: boolean;
}

export interface ASubAdminsState extends CommonState {
	list: ASubAdmin[];
}

export interface AServersState extends CommonState {
	list: AServer[];
}

export interface ASyncUsersState extends CommonState {
	list: ASyncUser[];
	loadingRefreshCompanies: boolean;
}

export interface AUsersState extends CommonState {
	list: AUser[];
}

export interface DbconfigsState {
	updatedAt: number;
	error: string;
	errorTrigger: number;
	loading: boolean;
}

export interface ErrorTrigger {
	error: string;
	status: number;
	data: any;
}

export interface InventoryItemsState {
	hasMorePages: boolean;
	isPending: boolean;
	list: InventoryItem[];
	search: string[];
	updatedAt: number;
}

export interface SearchState {
	applyFilters: boolean;
	isLoading: boolean;
	list: {
		documents: string[];
		total: 0;
	};
	updatedAt: number;
}

export interface SchedulerState {
	schedulerScrollTo: number;
	isDragging: boolean;
	schedulerResolution: {
		mode: '15-minutes' | '30-minutes' | '1-hour' | 'days';
		boxWidth: number;
		boxCount: number;
		oneHourWidth: number;
		oneMinuteWidth: number;
		intervals: string[];
	};
}

export interface FiltersState {
	isInitialized: boolean;
	list: Filter[];
	updatedAt: number;
}

export namespace Request {
	export enum Status {
		PENDING = 'pending',
		BEFORE_FULFILLED = 'before-fulfilled',
		FULFILLED = 'fulfilled',
		BEFORE_REJECTED = 'before-rejected',
		REJECTED = 'rejected',
	}

	export interface Info {
		name: string;
		status: Status;
		message: string;
		payload: Record<string, any>;
	}

	export interface State {
		updatedAt: number;
		list: Array<Info>;
	}
}

// Formats used in the application
const FORMATS = {
	SLASH_FORMAT: 'MM/DD/YYYY',
	SLASH_FORMAT_W_TIME: 'MM/DD/YYYY hh:mm A',
	DOT_FORMAT: 'MM.DD.YYYY',
	DOT_FORMAT_W_TIME: 'MM.DD.YYYY - hh:mm A',
	MONTH_NAME_FORMAT: 'Do MMMM, YYYY',
};

type Format = keyof typeof FORMATS;

export class Timing {
	public static formatDate = (date: Date) => moment(date).format(FORMATS.SLASH_FORMAT);

	public static formatDateToDateTime = (date: Date) => moment(date).format(FORMATS.SLASH_FORMAT_W_TIME);

	public static toDate = (value: string) => moment.utc(value, FORMATS.SLASH_FORMAT).toDate();

	public static dateTimeToDate = (value: string) => moment.utc(value, FORMATS.SLASH_FORMAT_W_TIME).toDate();

	public static toFormat = (value: string, format: Format) =>
		moment.utc(value, FORMATS.SLASH_FORMAT).format(FORMATS[format]);

	public static dateTimeToFormat = (value: string, format: Format) =>
		moment.utc(value, FORMATS.SLASH_FORMAT_W_TIME).format(FORMATS[format]);

	public static to = (value: string) => moment().to(value);

	public static isExpired = (expiryAt: number) => moment.unix(expiryAt).isBefore(moment());

	public static now = () => moment().valueOf();
}

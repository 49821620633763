import styled from '@emotion/styled';
import { Add, Close } from '@mui/icons-material';
import { Box, Grid, MenuItem, Select, TextField } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '../..';
import FilterCondition from '../../../../models/FilterCondition';
import FilterField from '../../../../models/FilterField';
import FilterMatchMode from '../../../../models/FilterMatchMode';
import FilterMode from '../../../../models/FilterMode';
import { useAppDispatch } from '../../../../store';
import { useOrderPhasesState } from '../../../../store/selectors';
import { multidragActions } from '../../../../store/slices/multidrag.slice';
import searchAsyncActions from '../../../../store/actions/search.action';
import { searchActions } from '../../../../store/slices/search.slice';
import { OrdersTab } from 'models/OrdersTab';

const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': { padding: 0, borderRadius: 0, backgroundColor: '#0859a4' },
		'& .filter-item': {
			color: '#0859a4',
			'&.active': {
				fontWeight: 600,
			},
		},
	},
	menuWrapper: {
		width: 350,
		maxHeight: '60vh',
		overflowY: 'scroll',
		scrollBehavior: 'smooth',
		'&::-webkit-scrollbar': {
			width: 5,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#afcdfb',
			height: 30,
			'&:hover': {
				backgroundColor: '#81a4d8',
			},
			'&:active': {
				backgroundColor: '#5f83b8',
			},
		},
	},
	textField: {
		// border: '2px solid #ffffff',
		borderRadius: 20,
		padding: 0,
		height: 24,
		lineHeight: 24,
	},
	input: {
		height: 24,
		textAlign: 'center',
		fontSize: '.6rem',
		color: '#ffffff',
		paddingTop: 0,
		paddingBottom: 0,
	},
	select: {
		border: '2px solid #ffffff',
		borderRadius: 20,
		padding: 0,
		height: 24,
	},
	selectIcons: {
		fill: 'white',
	},
	outlined: {
		border: 'none',
	},
});

const StyledTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: 'white',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'white',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'white',
			borderWidth: 2,
			borderRadius: 20,
		},
		'&:hover fieldset': {
			borderColor: 'white',
		},
		'&.Mui-focused fieldset': {
			borderColor: 'white',
		},
	},
});

interface Props {
	setIsSearchAdvanced: React.Dispatch<React.SetStateAction<boolean>>;
	setFrom: React.Dispatch<React.SetStateAction<number>>;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	refreshSearch: any;
	filterFields: FilterField[];
	filterModes: FilterMode[];
	matchMode: FilterMatchMode;
	conditions: FilterCondition[];
	setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setMatchMode: React.Dispatch<React.SetStateAction<FilterMatchMode>>;
	setConditions: React.Dispatch<React.SetStateAction<FilterCondition[]>>;
	tab: OrdersTab;
	from: any;
}

const FilterMenu = ({
	setIsSearchAdvanced,
	setFrom,
	setCurrentPage,
	refreshSearch,
	filterFields,
	filterModes,
	from,
	matchMode,
	conditions,
	setFilterOpen,
	setMatchMode,
	setConditions,
	tab,
	...props
}: Props & MenuProps) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const orderPhasesState = useOrderPhasesState();

	const params = useParams<{
		companyName: string;
	}>();

	const handleChange = React.useCallback(
		(index: number, key: keyof Omit<FilterCondition, 'type'>, value: FilterMode['value'] | string) => {
			const newFilterConditions = [...conditions];
			if (key === 'mode') {
				newFilterConditions[index][key] = value as FilterMode['value'];

				if (value === 'set' || value === '!set') {
					newFilterConditions[index].value = '';
					newFilterConditions[index].valueTwo = '';
				}
			} else {
				newFilterConditions[index][key] = value;

				if (key === 'field') {
					const findType = filterFields.find((element) => element.name === newFilterConditions[index].field);
					if (findType) {
						newFilterConditions[index].type = findType.type;
					}
				}
			}

			setConditions(newFilterConditions);
		},
		[conditions]
	);

	const addFilterCondition = React.useCallback(() => {
		setConditions((prev) => [
			...prev,
			{
				field: filterFields[0].name,
				mode: filterModes[0].value,
				type: filterFields[0].type,
				value: '',
				valueTwo: '',
			},
		]);
	}, [conditions]);

	const removeFilterCondition = React.useCallback(
		(index: number) => {
			const newFilterConditions = [...conditions];
			newFilterConditions.splice(index, 1);

			if (newFilterConditions.length === 0) {
				dispatch(searchActions.clearFilters());
				newFilterConditions.push({
					field: filterFields[0].name,
					mode: filterModes[0].value,
					type: filterFields[0].type,
					value: '',
					valueTwo: '',
				});
				setConditions(newFilterConditions);

				setFilterOpen(false);
				refreshSearch(from, tab);

				return;
			}

			setConditions(newFilterConditions);
			dispatch(
				searchAsyncActions.advanced({
					companyName: params.companyName,
					matchMode,
					conditions: newFilterConditions,
					from,
					tab,
				})
			);
		},
		[matchMode, conditions, tab]
	);

	const handleApply = React.useCallback(() => {
		dispatch(multidragActions.resetSelectedOrders());
		setFilterOpen(false);
		setFrom(0);
		setCurrentPage(1);
		setIsSearchAdvanced(true);
		if (
			conditions.length === 1 &&
			conditions[0].field === 'orderNo' &&
			conditions[0].mode === 'contains' &&
			conditions[0].type === 'TEXT' &&
			conditions[0].value === ''
		) {
			dispatch(searchActions.clearFilters());
			return;
		}

		dispatch(
			searchAsyncActions.advanced({
				companyName: params.companyName,
				matchMode,
				conditions,
				from,
				tab,
			})
		);
	}, [matchMode, conditions, tab]);

	const canShow = React.useCallback((element) => {
		if (element.field === 'orderStatus' && (element.mode === 'eq' || element.mode === '!eq')) {
			return false;
		}

		if (element.mode === 'set' || element.mode === '!set') {
			return false;
		}

		return true;
	}, []);

	return (
		<Menu
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			{...props}
			className={classes.root}
		>
			<Box className={classes.menuWrapper}>
				<Box sx={{ padding: '0 .5em' }}>
					<Grid container spacing={1}>
						<Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<Box style={{ flexShrink: 0, paddingRight: 8 }}>
								<Typography color="light" variant="subheading2">
									Match if
								</Typography>
							</Box>
							<Select
								variant="outlined"
								className={classes.select}
								inputProps={{ className: classes.input }}
								classes={{
									icon: classes.selectIcons,
									outlined: classes.outlined,
									nativeInput: classes.input,
								}}
								value={matchMode}
								onChange={(e) => setMatchMode(e.target.value as FilterMatchMode)}
							>
								<MenuItem value="any">Any</MenuItem>
								<MenuItem value="all">All</MenuItem>
							</Select>
							<Box style={{ flexShrink: 0, paddingLeft: 8 }}>
								<Typography color="light" variant="subheading2">
									of the following criteria are true
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
				{conditions.map((element, index) => (
					<Box
						sx={{ borderBottom: '1px solid #ffffff', padding: `${index === 0 ? '0' : '1em'} .5em 1em` }}
						key={index}
					>
						<Grid paddingY={1} container spacing={1}>
							<Grid item xs={6}>
								<Select
									style={{ display: 'flex', flexGrow: 1 }}
									variant="outlined"
									className={classes.select}
									inputProps={{ className: classes.input }}
									classes={{
										icon: classes.selectIcons,
										outlined: classes.outlined,
										nativeInput: classes.input,
									}}
									value={element.field}
									onChange={(e) => handleChange(index, 'field', e.target.value)}
								>
									{filterFields.map((element) => (
										<MenuItem key={element.name} value={element.name}>
											{element.label}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={6}>
								<Select
									style={{ display: 'flex', flexGrow: 1 }}
									variant="outlined"
									className={classes.select}
									inputProps={{ className: classes.input }}
									classes={{
										icon: classes.selectIcons,
										outlined: classes.outlined,
									}}
									value={element.mode}
									onChange={(e) => handleChange(index, 'mode', e.target.value)}
								>
									{filterModes.map((filterMode, filterIndex) => (
										<MenuItem value={filterMode.value} key={`${index}-${filterIndex}`}>
											{filterMode.name}
										</MenuItem>
									))}
								</Select>
							</Grid>
						</Grid>
						<Grid paddingBottom={1} container spacing={1}>
							{canShow(element) ? (
								<Grid item xs={element.mode === 'between' ? 5 : 10}>
									<StyledTextField
										style={{ display: 'flex', flexGrow: 1 }}
										className={classes.textField}
										inputProps={{ className: classes.input }}
										value={element.value}
										onChange={(e) => handleChange(index, 'value', e.target.value)}
										fullWidth
										variant={'outlined'}
										size="small"
									/>
								</Grid>
							) : null}
							{element.mode === 'between' && (
								<Grid item xs={5}>
									<StyledTextField
										style={{ display: 'flex', flexGrow: 1 }}
										className={classes.textField}
										inputProps={{ className: classes.input }}
										value={element.valueTwo}
										onChange={(e) => handleChange(index, 'valueTwo', e.target.value)}
										fullWidth
										variant={'outlined'}
										size="small"
									/>
								</Grid>
							)}
							{element.field === 'orderStatus' && (element.mode === 'eq' || element.mode === '!eq') && (
								<Grid item xs={10}>
									<Select
										style={{ display: 'flex', flexGrow: 1 }}
										variant="outlined"
										className={classes.select}
										inputProps={{ className: classes.input }}
										classes={{
											icon: classes.selectIcons,
											outlined: classes.outlined,
										}}
										value={element.value}
										onChange={(e) => handleChange(index, 'value', e.target.value)}
									>
										{orderPhasesState.list.map((filterValue, filterIndex) => (
											<MenuItem value={filterValue.phaseId} key={`${index}-${filterIndex}`}>
												{filterValue.phaseId}
											</MenuItem>
										))}
									</Select>
								</Grid>
							)}
							{(element.mode === 'set' || element.mode === '!set') && <Grid item xs={10} />}
							<Grid item xs={2}>
								<button
									// variant="contained"
									onClick={() => {
										removeFilterCondition(index);
									}}
									style={{
										backgroundColor: '#ffffff',
										borderRadius: '50%',
										height: 24,
										width: 24,
										// padding: '1em',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Close sx={{ color: '#FA2E2E', fontSize: '1rem' }} />
								</button>
							</Grid>
						</Grid>
					</Box>
				))}

				<Box justifyContent="center" alignItems="center" display="flex" marginTop="1em" minHeight={40}>
					<button
						onClick={addFilterCondition}
						style={{
							backgroundColor: '#ffffff',
							borderRadius: 24,
							height: 24,
							width: 24,
							padding: '1em',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Add sx={{ color: '#0859a4', fontSize: '1rem' }} />
					</button>
					<Box width={16} />
					<button
						onClick={handleApply}
						style={{
							backgroundColor: '#ffffff',
							borderRadius: 24,
							height: 24,
							padding: '1em',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						Apply
					</button>
				</Box>
			</Box>
		</Menu>
	);
};

export default FilterMenu;

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AServer from '../../../models/Admin/AServer';
import AUser from '../../../models/Admin/AUser';
import { useAppDispatch } from '../../../store';
import RequestManager from '../../../store/request-manager';
import { useAServersState, useRequestState } from '../../../store/selectors';
import theme from '../../../theme';
import { createSyncUser, updateSyncUser } from 'api-services/sync-user';
import Company from 'models/Company';
import { updateCompanies } from 'api-services/server';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
	container: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
});

export interface Props {
	server: AServer | null;
	open: boolean;
	onClose: () => void;
}

const Modal: React.FC<Props> = ({ server, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const aServersState = useAServersState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const [serverId, setServerId] = React.useState<string>('');
	const [companies, setCompanies] = React.useState<Company[]>([]);
	const [companyIds, setCompanyIds] = React.useState<string[]>([]);
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!open || !server) {
			return;
		}

		setServerId(server.id);
		const notBlockedCompanies = server.companies.filter((company) => !company.blocked);
		const ids = notBlockedCompanies.map((company) => company.id);
		setCompanies(notBlockedCompanies);
		setCompanyIds(ids);
	}, [open]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={12}>
						<FormGroup>
							{server?.companies?.map((element) => (
								<FormControlLabel
									key={element.id}
									control={
										<Checkbox
											value={element.id}
											checked={companyIds
												.map((e) => parseInt(e, 10))
												.includes(parseInt(element.id, 10))}
											onChange={(e) => {
												if (e.target.checked) {
													if (!companyIds.includes(element.id)) {
														setCompanyIds((prev) => [...prev, element.id]);
													}
												} else {
													const findIndex = companyIds.findIndex(
														(element1) =>
															parseInt(element1, 10) === parseInt(element.id, 10)
													);
													if (findIndex !== -1) {
														const newCompanyIds = [...companyIds];
														newCompanyIds.splice(findIndex, 1);

														setCompanyIds(newCompanyIds);
													}
												}
											}}
										/>
									}
									label={element.description}
								/>
							))}
						</FormGroup>
					</Grid>
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							onClick={() => {
								if (server) {
									updateCompanies(server.id, {
										companyIds,
									});
								}
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							{isLoading ? <CircularProgress size={18} /> : 'Save'}
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							sx={{ height: '40px' }}
							onClick={onClose}
							fullWidth
							variant="outlined"
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default Modal;

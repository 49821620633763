import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AServersState, Timing, ErrorTrigger, ASyncUsersState } from 'store/types';
import AServer from '../../models/Admin/AServer';
import { extractErrorMessageFromResponse } from 'lib/utils/redux';
import ASyncUser from 'models/Admin/ASyncUser';

const initialState: ASyncUsersState = {
	list: [],
	error: '',
	loading: false,
	loadingRefreshCompanies: false,
	errorTrigger: 0,
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aSyncUsers',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setIsLoadingRefreshCompanies: (state, action: PayloadAction<boolean>) => {
			state.loadingRefreshCompanies = action.payload;
		},
		setErrorTrigger: (state, action: PayloadAction<ErrorTrigger>) => {
			state.loading = false;
			state.errorTrigger = Timing.now();
			state.error = extractErrorMessageFromResponse(action.payload);
		},
		setList: (state, action: PayloadAction<ASyncUser[]>) => {
			state.loading = false;
			state.list = action.payload;
		},
		handleCreate: (state, action: PayloadAction<ASyncUser>) => {
			state.loading = false;
			state.list = [...state.list, action.payload];
		},
		handleUpdate: (state, action: PayloadAction<ASyncUser>) => {
			state.loading = false;
			const index = state.list.findIndex(
				(element) => element.id === action.payload.id && element.serverId === action.payload.serverId
			);
			if (index === -1) return;
			state.list[index] = action.payload;
		},
		handleDelete: (
			state,
			action: PayloadAction<{
				id: string;
				serverId: string;
			}>
		) => {
			state.loading = false;
			state.list = state.list.filter(
				(element) => element.id !== action.payload.id && element.serverId === action.payload.serverId
			);
		},
	},
});

export const {
	setIsLoading,
	setIsLoadingRefreshCompanies,
	setErrorTrigger,
	setList,
	handleCreate,
	handleUpdate,
	handleDelete,
} = slice.actions;

export default slice.reducer;

import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useASyncUsersState, useRequestState } from 'store/selectors';
import ASyncUser from '../../../models/Admin/ASyncUser';
import Company from '../../../models/Company';
import { useAppDispatch } from '../../../store';
import aSyncUsersAsyncActions from '../../../store/actions/aSyncUsers.action';
import CreateSyncUserModal from './CreateSyncUserModal';
import SyncUserModalEditCompanies from './SyncUserModalEditCompanies';
import { LS } from '@cyboticx/common';
import { AServersState } from 'store/types';
import API from '../../../lib/utils/API';
import { deleteSyncUser, refreshCompanies, syncCompanies } from 'api-services/sync-user';

const SyncUserRow = ({
	user,
	setUser,
	openDialog,
	openDialogEditCompanies,
}: {
	user: ASyncUser;
	setUser: React.Dispatch<React.SetStateAction<ASyncUser | null>>;
	openDialog: () => void;
	openDialogEditCompanies: () => void;
}) => {
	const dispatch = useAppDispatch();

	const [isSyncing, setIsSyncing] = React.useState<boolean>(false);
	const [isLoadingSyncComapnies, setIsLoadingSyncComapnies] = React.useState<boolean>(false);

	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell sx={{ fontWeight: 'bold' }}>{user.serverName}</TableCell>
			<TableCell sx={{ fontWeight: 'bold' }}>{user.username}</TableCell>
			<TableCell sx={{ fontWeight: 'bold' }}>
				{user.companies
					.filter((company) => !company.blocked)
					.map((company) => {
						return company.description;
					})
					.join(', ')}
			</TableCell>
			<TableCell sx={{ width: 1200 }} align="right">
				<Button
					style={{ width: 120 }}
					disabled={isLoadingSyncComapnies}
					onClick={async () => {
						toast.success('Refreshing Companies...', {
							icon: 'ℹ️',
						});
						setIsLoadingSyncComapnies(true);
						await refreshCompanies(user.id, {
							serverId: parseInt(user.serverId),
						});
						setIsLoadingSyncComapnies(false);
					}}
				>
					{isLoadingSyncComapnies ? <CircularProgress size={18} /> : 'Refresh Companies'}
				</Button>
				<Button
					style={{ width: 120 }}
					disabled={isSyncing}
					onClick={async () => {
						toast.success('Retrieving permissions...', {
							icon: 'ℹ️',
						});
						setIsSyncing(true);
						await syncCompanies(user.id, {
							serverId: parseInt(user.serverId),
						});
						setIsSyncing(false);
					}}
				>
					{isSyncing ? <CircularProgress size={18} /> : 'Synchronize'}
				</Button>
				{isSyncing && (
					<Button
						onClick={async () => {
							toast.success('Stopping sync...', {
								icon: 'ℹ️',
							});
						}}
					>
						{'STOP'}
					</Button>
				)}

				{/* <Button onClick={() => dispatch(aSyncUsersAsyncActions.block({ id: user.id }))}>
                                              {user.isBlocked ? 'UNBLOCK' : 'BLOCK'}
                                          </Button> */}

				<Button
					onClick={() => {
						setUser(user);
						openDialogEditCompanies();
					}}
				>
					EDIT COMPANIES
				</Button>

				<Button
					onClick={() => {
						setUser(user);
						openDialog();
					}}
				>
					EDIT
				</Button>
				<Button
					onClick={() => {
						deleteSyncUser(user.id, {
							serverId: parseInt(user.serverId),
						});
					}}
					color="error"
				>
					DELETE
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default SyncUserRow;

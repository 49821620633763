import { apiCall } from '../index';
import ASubAdmin from '../../models/Admin/ASubAdmin';
import store from '../../store';
import {
	setList,
	handleCreate,
	handleUpdate,
	setIsLoading,
	setErrorTrigger,
} from '../../store/slices/aSubAdmins.slice';

type CreateBody = {
	serverId: number;
	firstName?: string;
	lastName?: string;
	username: string;
	password: string;
};

type UpdateBody = {
	serverId: number;
	firstName?: string;
	lastName?: string;
	username: string;
	password: string;
};

export async function getSubAdmins() {
	store.dispatch(setIsLoading(true));
	const res = await apiCall<ASubAdmin[]>('admin/sub-admins');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(setList(res.data!));
}

export async function createSubAdmin(body: CreateBody) {
	store.dispatch(setIsLoading(true));

	console.log('body : ', body);

	const res = await apiCall<ASubAdmin>('admin/sub-admins', true, body, 'post');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleCreate(res.data!));
}

export async function updateSubAdmin(id: string, body: UpdateBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<ASubAdmin>(`admin/sub-admins/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

import { apiCall } from '../index';
import ASubAdmin from '../../models/Admin/ASubAdmin';
import store from '../../store';

import {
	setIsLoading,
	setErrorTrigger,
	setList,
	handleCreate,
	handleUpdate,
	handleDelete,
} from '../../store/slices/aServers.slice';
import DbConfig from '../../models/Admin/DbConfig';
import AServer from '../../models/Admin/AServer';

type CreateBody = {
	code: string | null;
	name: string;
	host: string;
	sqlHost: string;
	sqlPort: number;
	sqlDbname: string;
	sqlUsername: string;
	sqlPassword: string;
	redisHost: string;
	redisPort: number;
	redisPassword: string;
};

type UpdateBody = {
	code: string | null;
	name: string;
	host: string;
	sqlHost: string;
	sqlPort: number;
	sqlDbname: string;
	sqlUsername: string;
	sqlPassword: string;
	redisHost: string;
	redisPort: number;
	redisPassword: string;
};

type UpdateCompaniesBody = {
	companyIds: string[];
};

export async function getServers() {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AServer[]>('admin/servers');
	store.dispatch(setIsLoading(false));
	console.log('res.data servers haha : ', res.data);
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(setList(res.data!));
}

export async function createServer(body: CreateBody) {
	store.dispatch(setIsLoading(true));

	console.log('body : ', body);

	const res = await apiCall<AServer>('admin/servers', true, body, 'post');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleCreate(res.data!));
}

export async function updateServer(id: string, body: UpdateBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AServer>(`admin/servers/update/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function updateCompanies(id: string, body: UpdateCompaniesBody) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<AServer>(`admin/servers/update-companies/${id}`, true, body, 'put');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleUpdate(res.data!));
}

export async function deleteServer(id: string) {
	store.dispatch(setIsLoading(true));

	const res = await apiCall<{
		status: 200;
		message: 'ok';
	}>(`admin/servers/${id}`, true, undefined, 'delete');
	store.dispatch(setIsLoading(false));
	if (res.status !== 200) {
		store.dispatch(setErrorTrigger(res));
		return;
	}
	store.dispatch(handleDelete(id));
}

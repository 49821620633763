import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useAServersState, useAUsersState } from 'store/selectors';
import AUser from '../../../models/Admin/AUser';
import { useAppDispatch } from '../../../store';
import aUsersAsyncActions from '../../../store/actions/aUsers.action';
import CreateUserModal from './CreateUserModal';
import UserModalEditCompanies from './UserModalEditCompanies';
import { blockUser, deleteUser } from 'api-services/user';

const UsersTab = () => {
	const dispatch = useAppDispatch();

	const aServersState = useAServersState();
	const users = useAUsersState();

	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [openModalEditCompanies, setOpenModalEditCompanies] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<AUser | null>(null);

	const closeDialog = React.useCallback(() => {
		setOpenModal(false);
	}, []);
	const openDialog = React.useCallback(() => {
		setOpenModal(true);
	}, []);

	const closeDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(false);
	}, []);
	const openDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(true);
	}, []);

	const getServer = React.useCallback(
		(id: string) => {
			const server = aServersState.list.find((element) => element.id === id);
			if (server) {
				return server.name;
			}

			return '';
		},
		[aServersState.list]
	);

	const getServerCode = React.useCallback(
		(id: string) => {
			const server = aServersState.list.find((element) => element.id === id);
			if (server) {
				return server.code;
			}

			return '000000';
		},
		[aServersState.list]
	);

	return (
		<>
			<CreateUserModal user={user} onClose={closeDialog} open={openModal} />
			<UserModalEditCompanies user={user} onClose={closeDialogEditCompanies} open={openModalEditCompanies} />

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="Users table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: '#0859a4' }} component="h6">
									FULL NAME
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									CODE
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									USERNAME
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									SERVER
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									COMPANIES
								</TableCell>
								<TableCell
									sx={{ color: '#0859a4', paddingLeft: 3, paddingRight: 3 }}
									component="h6"
									align="right"
								>
									ACTION
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.list.map((user) => (
								<TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell sx={{ fontWeight: 'bold' }}>
										{user.firstName} {user.lastName}
									</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}> {user.server.code} </TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}> {user.username} </TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}> {user.server.name} </TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}>
										{' '}
										{user.companies
											.filter((company) => !company.blocked)
											.map((company) => {
												return company.description;
											})
											.join(', ')}{' '}
									</TableCell>
									<TableCell sx={{ width: 350 }} align="right">
										{/* <Button>LOGIN</Button> */}
										<Button
											onClick={async () => {
												await blockUser(user.id, {
													serverId: parseInt(user.serverId),
												});
											}}
										>
											{user.isBlocked ? 'UNBLOCK' : 'BLOCK'}
										</Button>
										<Button
											onClick={() => {
												setUser(user);
												openDialogEditCompanies();
											}}
										>
											EDIT Companies
										</Button>
										<Button
											onClick={() => {
												setUser(user);
												openDialog();
											}}
										>
											EDIT
										</Button>
										<Button
											onClick={async () => {
												await deleteUser(user.id, {
													serverId: parseInt(user.serverId),
												});
											}}
											color="error"
										>
											DELETE
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default UsersTab;

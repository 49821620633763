import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useAServersState } from 'store/selectors';
import AServer from '../../../models/Admin/AServer';
import DbConfig from '../../../models/Admin/DbConfig';
import { useAppDispatch } from '../../../store';
import aServersAsyncActions from '../../../store/actions/aServers.action';
import CreateServerModal from './CreateServerModal';
import ServerModalEditCompanies from './ServerModalEditCompanies';
import { deleteServer } from '../../../api-services/server/index';
import toast from 'react-hot-toast';

const ServersTab = () => {
	const dispatch = useAppDispatch();

	const servers = useAServersState();

	useEffect(() => {
		console.log('haha servers : ', servers);
	}, [servers]);

	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [openModalEditCompanies, setOpenModalEditCompanies] = React.useState<boolean>(false);
	const [openConfigureModal, setOpenConfigureModal] = React.useState<boolean>(false);
	const [server, setServer] = React.useState<AServer | null>(null);
	const [dbconfig, setDbconfig] = React.useState<DbConfig | null>(null);

	const closeDialog = React.useCallback(() => {
		setOpenModal(false);
	}, []);
	const openDialog = React.useCallback(() => {
		setOpenModal(true);
	}, []);

	const closeDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(false);
	}, []);
	const openDialogEditCompanies = React.useCallback(() => {
		setOpenModalEditCompanies(true);
	}, []);

	return (
		<>
			<CreateServerModal server={server} onClose={closeDialog} open={openModal} />
			<ServerModalEditCompanies
				server={server}
				onClose={closeDialogEditCompanies}
				open={openModalEditCompanies}
			/>

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="Users table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: '#0859a4' }} component="h6">
									NAME
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									CODE
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									HOST
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									COMPANIES
								</TableCell>
								<TableCell
									sx={{ color: '#0859a4', paddingLeft: 3, paddingRight: 3 }}
									component="h6"
									align="right"
								>
									ACTION
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{servers.loading ? (
								<div
									style={{
										width: '100vw',
										height: 200,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress size={50} />
								</div>
							) : (
								servers.list.map((server, index) => (
									<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell sx={{ fontWeight: 'bold' }}>{server.name}</TableCell>
										<TableCell sx={{ fontWeight: 'bold' }}> {server.code} </TableCell>
										<TableCell sx={{ fontWeight: 'bold' }}> {server.host} </TableCell>
										<TableCell sx={{ fontWeight: 'bold' }}>
											{server?.companies
												?.filter((company) => !company.blocked)
												.map((company, index) => {
													return <div>{`${index + 1}. ${company.description}`}</div>;
												})}
										</TableCell>
										<TableCell sx={{ width: 350 }} align="right">
											{/* <Button>LOGIN</Button> */}
											<Button
												onClick={() => {
													setServer(server);
													openDialogEditCompanies();
												}}
											>
												EDIT COMPANIES
											</Button>
											<Button
												onClick={() => {
													setServer(server);
													setDbconfig(server?.dbconfig ?? null);
													openDialog();
												}}
											>
												EDIT
											</Button>
											<Button onClick={() => deleteServer(server.id)} color="error">
												DELETE
											</Button>
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default ServersTab;

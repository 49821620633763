import { Box, Button, CircularProgress, Dialog, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import AServer from '../../../models/Admin/AServer';
import { useAppDispatch } from '../../../store';
import { createServer, updateServer } from '../../../api-services/server';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});
export interface Props {
	server?: AServer | null;
	open: boolean;
	onClose: () => void;
}
const CreateServerModal: React.FC<Props> = ({ server, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [code, setCode] = React.useState<string>('');
	const [name, setName] = React.useState<string>('');
	const [host, setHost] = React.useState<string>('');

	const [sqlHost, setSqlHost] = React.useState<string>('');
	const [sqlPort, setSqlPort] = React.useState<string>('');
	const [sqlDbname, setSqlDbName] = React.useState<string>('');
	const [sqlUsername, setSqlUserName] = React.useState<string>('');
	const [sqlPassword, setSqlPassword] = React.useState<string>('');
	const [redisHost, setRedisHost] = React.useState<string>('');
	const [redisPort, setRedisPort] = React.useState<string>('');
	const [redisPassword, setRedisPassword] = React.useState<string>('');

	React.useEffect(() => {
		if (!open || !server) {
			return;
		}

		setCode(server.code ?? '');
		setName(server.name ?? '');
		setHost(server.host ?? '');

		setSqlHost(server.dbconfig.host ?? '');
		setSqlPort(server.dbconfig.port.toString());
		setSqlDbName(server.dbconfig.dbname);
		setSqlUserName(server.dbconfig.username);
		setSqlPassword(server.dbconfig.password);
		setRedisHost(server.dbconfig.redisHost);
		setRedisPort(server.dbconfig.redisPort.toString());
		setRedisPassword(server.dbconfig.redisPassword);
	}, [open]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Code'}
							value={code}
							onChange={(e) => setCode(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Name'}
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Host'}
							value={host}
							onChange={(e) => setHost(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Sql Host'}
							value={sqlHost}
							onChange={(e) => setSqlHost(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Sql Port'}
							value={sqlPort}
							onChange={(e) => setSqlPort(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Sql Database Name'}
							value={sqlDbname}
							onChange={(e) => setSqlDbName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Sql Username'}
							value={sqlUsername}
							onChange={(e) => setSqlUserName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Sql Password'}
							value={sqlPassword}
							onChange={(e) => setSqlPassword(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Redis Host'}
							value={redisHost}
							onChange={(e) => setRedisHost(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Redis Port'}
							value={redisPort}
							onChange={(e) => setRedisPort(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Redis Password'}
							value={redisPassword}
							onChange={(e) => setRedisPassword(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							onClick={async () => {
								setIsLoading(true);
								if (server) {
									await updateServer(server.id, {
										code,
										host,
										name,
										redisHost,
										redisPassword,
										redisPort: parseInt(redisPort),
										sqlDbname,
										sqlHost,
										sqlPassword,
										sqlPort: parseInt(sqlPort),
										sqlUsername,
									});
								} else {
									await createServer({
										code,
										host,
										name,
										redisHost,
										redisPassword,
										redisPort: parseInt(redisPort),
										sqlDbname,
										sqlHost,
										sqlPassword,
										sqlPort: parseInt(sqlPort),
										sqlUsername,
									});
								}
								setIsLoading(false);

								onClose();
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
							disabled={isLoading}
						>
							{isLoading ? <CircularProgress size={18} /> : 'Save'}
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button sx={{ height: '40px' }} onClick={onClose} fullWidth variant="outlined">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default CreateServerModal;
